import Work1 from "../../assets/work-1.svg"
import Work2 from "../../assets/work-2.svg"
import Work3 from "../../assets/work-3.svg"
import Work4 from "../../assets/work-4.svg"
import Work5 from "../../assets/work-5.svg"
import Work6 from "../../assets/work-6.svg"
const Menu = [
    {
      id: 1,
      image: Work1,
      title: "Web3",
      category: "Learning",
    },
    {
      id: 2,
      image: Work2,
      title: "Yeomra.com",
      category: "Project",
    },
    {
      id: 3,
      image: Work3,
      title: "SDET",
      category: "Learning",
    },
    ,
    {
      id: 4,
      image: Work4,
      title: "signaturetattooz.com",
      category: "Project",
    },
    ,
    {
      id: 5,
      image: Work5,
      title: "Web Development",
      category: "Learning",
    },
    ,
    {
      id: 6,
      image: Work6,
      title: "Photography and Games",
      category: "Photography",
    },
  ];
  
export default Menu